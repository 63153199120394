<template>
  <div>
    
    <section>
      <app-tour :steps="steps"/>
     <b-row class="match-height">
        <b-col lg="7" >      
              <card-analytic-revenue/>
        </b-col>
        <b-col   lg="5" >
          <card-text-alignment />
          <progress-stripped-vue/>      
        </b-col>
    </b-row>  
 
  </section>
  </div>
   
      
    

  
</template>

<script>
import { BRow, BCol,BCard,BOverlay,BButton } from 'bootstrap-vue'

import CardAnalyticRevenue from './CardAnalyticRevenue.vue'
import CardTextAlignment from './CardTextAlignment.vue'
import ProgressStrippedVue from './ProgressStripped.vue'
import FutureGrowthGraph from './FutureGrowthGraph.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'






export default {
  name: 'ShepherdExample',
  components: {
    BRow,
    BCol,
    CardAnalyticRevenue,
    CardTextAlignment,
    ProgressStrippedVue,
    FutureGrowthGraph,
    AppTour,
    BCard,
    BCardCode,
    BOverlay,
    BButton
  },  
  directives: {
    Ripple,
  },
  data () {
        return {
          steps: [
            {
              target: '#mychart',
              header: {
                title: 'Growth in the Last 10 Years',
              },
              content: 'Here you can see the Growth of Earnings, Revenue and Free Cashflow of the recent 10 years',
            },
            {
              target: '#Rates',
              header: {
                title: 'Growth-Rates',
              },
              content: 'Here you can see the the different growth rates. Color and lenght indicate how good certain values are.',
            },
            {
              target: '#card-text-alignment',
              header: {
                title: 'Questions ?',
              },
              content: 'If you have questions about certain terms or want an explaination why we only refer to certain values you can read an article about any time, by clicking this button',
            },
            {
              target: '#__BVID__1119___BV_tab_button__',
              header: {
                title: 'Is it Profitable ?',
              },
              content: 'to see if the company is Profitable. Click here :)',
            },
          ],
        }
    },   
  mounted() {
    this.checkTutorial()
  },
  methods: {
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>

