<template>
  <b-card>
    <b-card-header class="mb-3">
      <b-card-title>10 year Growth</b-card-title>
      <b-card-sub-title class="left-alignment d-none d-md-block">Data is shown as yearly disclosed results.</b-card-sub-title>
    </b-card-header>

    <b-card-body class="pb-1">
      <!-- apex chart -->
      <vue-apex-charts
        type="area"
        id="mychart"
        :series="series"
        :options="RevenueCardData.revenueComparisonLine.chartOptions"
      />
      <div class="d-flex flex-wrap justify-content-center">
        <!-- Dropdowns -->
        <b-dropdown v-model="selectedRevenue1" class="mr-2 mb-2"
        variant="flat-primary" :text="selectedRevenue1">
          <b-dropdown-item @click="selectedRevenue1 = 'Revenue'; updateChart()">Revenue</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue1 = 'FreeCashflow'; updateChart()">Free Cashflow</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue1 = 'Earnings'; updateChart()">Earnings</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue1 = 'EBIT'; updateChart()">EBIT</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue1 = 'EBITDA'; updateChart()">EBITDA</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue1 = 'OperatingIncome'; updateChart()">Operating Income</b-dropdown-item>
        </b-dropdown>

        <b-dropdown v-model="selectedRevenue2" class="mr-2 mb-2"
        variant="flat-warning" :text="selectedRevenue2">
          <b-dropdown-item @click="selectedRevenue2 = 'Revenue'; updateChart()">Revenue</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue2 = 'FreeCashflow'; updateChart()">Free Cashflow</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue2 = 'Earnings'; updateChart()">Earnings</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue2 = 'EBIT'; updateChart()">EBIT</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue2 = 'EBITDA'; updateChart()">EBITDA</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue2 = 'OperatingIncome'; updateChart()">Operating Income</b-dropdown-item>
        </b-dropdown>

        <b-dropdown v-model="selectedRevenue3" class="mr-2 mb-2"
        variant="flat-success" :text="selectedRevenue3">
          <b-dropdown-item @click="selectedRevenue3 = 'Revenue'; updateChart()">Revenue</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue3 = 'FreeCashflow'; updateChart()">Free Cashflow</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue3 = 'Earnings'; updateChart()">Earnings</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue3 = 'EBIT'; updateChart()">EBIT</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue3 = 'EBITDA'; updateChart()">EBITDA</b-dropdown-item>
          <b-dropdown-item @click="selectedRevenue3 = 'OperatingIncome'; updateChart()">Operating Income</b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BButton, BDropdownItem
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { db } from '@/firebase/index'
import { EventBus } from '@/main'
import { kFormatter } from '@/@core/utils/filter'
import { doc, getDoc } from "firebase/firestore";
import { mapState } from 'vuex'
import RevenueCardData  from './RevenueCardData.js'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BButton,
    BDropdownItem
  },
  computed: {
  joke() {
    return this.$store.getters.getCurrentCompdata;
  },
  series() {
    const seriesData = [];

    if (this.selectedRevenue1 && this.joke[this.selectedRevenue1]) {
      seriesData.push({
        name: this.selectedRevenue1,
        data: Object.values(this.joke[this.selectedRevenue1]),
      });
    }
    if (this.selectedRevenue2 && this.joke[this.selectedRevenue2]) {
      seriesData.push({
        name: this.selectedRevenue2,
        data: Object.values(this.joke[this.selectedRevenue2]),
      });
    }
    if (this.selectedRevenue3 && this.joke[this.selectedRevenue3]) {
      seriesData.push({
        name: this.selectedRevenue3,
        data: Object.values(this.joke[this.selectedRevenue3]),
      });
    }

    // Fetch data for EBIT, EBITDA, and Operating Income
    if (this.selectedRevenue1 === 'EBIT' || this.selectedRevenue2 === 'EBIT' || this.selectedRevenue3 === 'EBIT') {
      seriesData.push({
        name: 'EBIT',
        data: Object.values(this.$store.getters.getCurrentCompdata.IncomeStatement.ebit),
      });
    }
    if (this.selectedRevenue1 === 'EBITDA' || this.selectedRevenue2 === 'EBITDA' || this.selectedRevenue3 === 'EBITDA') {
      seriesData.push({
        name: 'EBITDA',
        data: Object.values(this.$store.getters.getCurrentCompdata.IncomeStatement.ebitda),
      });
    }
    if (this.selectedRevenue1 === 'OperatingIncome' || this.selectedRevenue2 === 'OperatingIncome' || this.selectedRevenue3 === 'OperatingIncome') {
      seriesData.push({
        name: 'Operating Income',
        data: Object.values(this.$store.getters.getCurrentCompdata.IncomeStatement.OperatingIncome),
      });
    }
    return seriesData;
  },
},

  data() {
    return {
      RevenueCardData,
      selectedRevenue1: 'Revenue', // Default selected revenue option
      selectedRevenue2: 'Free Cashflow', // Default selected revenue option
      selectedRevenue3: 'Earnings', // Default selected revenue option
    };
  },
  created() {
    this.updateXAxisLabels();
  },
  methods: {
    updateXAxisLabels() {
      const xaxis = RevenueCardData.revenueComparisonLine.chartOptions.xaxis;
      // Set the categories to be the keys of TotalDebt or TotalEquity object
      xaxis.categories = Object.keys(this.$store.getters.getCurrentCompdata.TotalDebt);
    },
    updateChart() {
      // Method to update the chart when the dropdown selection changes
      // No need to implement anything here as the chart will automatically update with the computed properties
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .mb-2 {
    margin-bottom: 10px; /* Abstand zwischen Dropdowns im mobilen Modus */
  }
}
</style>
