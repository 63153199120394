<template>
  <section id="card-text-alignment">
    <b-row>
      <!-- left align -->
      <b-col
        md="6"
        lg="12"
      >
        <b-card
          title="Which Metric are relevant to measure Growth"
          class="mb-1"
        >
          <b-card-text>
            You might be familiar with the Terms: Revenue,Earnings and Free-Cashflow. If you do not know them yet, you can click below to learn what these terms mean and why the are the most relevant to measure Growth of a specific Company
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            v-b-modal.modal-center
          >
            Explain it to me
          </b-button>
          <b-modal
      id="modal-center"
      centered
      title="Growth Analysis"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <h2>Revenue:</h2>
    <p>
        <strong>Definition:</strong> Revenue is the total income generated by a company from its primary operations, such as sales of goods or services.
    </p>
    <p>
        <strong>Formula:</strong> Revenue = (Total Sales)
    </p>

    <h2>Earnings:</h2>
    <p>
        <strong>Definition:</strong> Earnings refer to the company's profits after deducting expenses, taxes, and other costs from its revenue.
    </p>
    <p>
        <strong>Formula:</strong> Earnings = (Revenue - Expenses)
    </p>

    <h2>Free Cash Flow:</h2>
    <p>
        <strong>Definition:</strong> Free Cash Flow represents the cash generated by a company's operations that is available for distribution to investors or for reinvestment.
    </p>
    <p>
        <strong>Formula:</strong> Free Cash Flow = (Operating Cash Flow - Capital Expenditures)
    </p>
      </b-card-text>
    </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
