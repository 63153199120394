<template>
  <b-card :title="cardTitle" id="Rates" :class="borderClass">
    <b-card-text>All of the given Rates are in %</b-card-text>

    <div class="demo-vertical-spacing">
      <div>
        <label>Last-Year-Revenue-Growth-rate</label>
        <b-progress
          show-value
          :value="YearRevenue"
          max="25"
          striped
          :variant="variant(YearRevenue)"
        />
      </div>
      <div>
        <label>Last-Year-Earnings-Growth-rate</label>
        <b-progress
          show-value
          :value="YearEarning"
          max="25"
          striped
          :variant="variant(YearEarning)"
        />
      </div>
      <div>
        <label>Last-Year-Free-Cashflow-Growth-rate</label>
        <b-progress
          show-value
          :value="YearFCF"
          max="25"
          striped
          :variant="variant(YearFCF)"
        />
      </div>
      <div>
        <label>Past-3-Years-Free-Cashflow-Growth-rate</label>
        <b-progress
          show-value
          :value="ThreeFCF"
          max="25"
          striped
          :variant="variant(ThreeFCF)"
        />
      </div>
      <div>
        <label>Past-3-Years-Revenue-Growth-rate</label>
        <b-progress
          show-value
          :value="ThreeRevenue"
          max="25"
          striped
          :variant="variant(ThreeRevenue)"
        />
      </div>
      <div>
        <label>Past-3-Years-Earnings-Growth-rate</label>
        <b-progress
          show-value
          :value="ThreeEarning"
          max="25"
          striped
          :variant="variant(ThreeEarning)"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BProgress, BCardText, BCardBody, BCard } from 'bootstrap-vue'

export default {
  components: {
    BProgress,
    BCardText,
    BCardBody,
    BCard,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata;
    },
    YearRevenue() {
      return this.joke?.RevenueGrowth ? Object.values(this.joke.RevenueGrowth).slice(-1)[0] : 0;
    },
    YearEarning() {
      return this.joke?.EarningsGrowth ? Object.values(this.joke.EarningsGrowth).slice(-1)[0] : 0;
    },
    YearFCF() {
      return this.joke?.FcfGrowth ? Object.values(this.joke.FcfGrowth).slice(-1)[0] : 0;
    },
    ThreeEarning() {
      return this.joke?.EarningsGrowth 
        ? Object.values(this.joke.EarningsGrowth).slice(-3).reduce((a, b) => a + b, 0) / 3
        : 0;
    },
    ThreeFCF() {
      return this.joke?.FcfGrowth 
        ? Object.values(this.joke.FcfGrowth).slice(-3).reduce((a, b) => a + b, 0) / 3
        : 0;
    },
    ThreeRevenue() {
      return this.joke?.RevenueGrowth 
        ? Object.values(this.joke.RevenueGrowth).slice(-3).reduce((a, b) => a + b, 0) /3
        : 0;
    },
    averageGrowthRate() {
      const values = [this.YearRevenue, this.YearEarning, this.YearFCF, this.ThreeRevenue, this.ThreeEarning, this.ThreeFCF];
      const sum = values.reduce((a, b) => a + b, 0);
      return sum / values.length;
    },
    borderClass() {
      if (this.averageGrowthRate > 10) {
        return 'border-success';
      }
      if (this.averageGrowthRate >= 5 && this.averageGrowthRate <= 10) {
        return 'border-warning';
      }
      return 'border-danger';
    },
    cardTitle() {
      return `Growth-Rates`;
    }
  },
  data() {
    return {
      codeStripped: '',
    }
  },
  methods: {
    variant(value) {
      if (value < 5) {
        return "danger";
      }
      if (value >= 5 && value <= 10) {
        return "warning";
      }
      return "success";
    }
  }
}
</script>

<style scoped>
.border-danger {
  border: 2px solid #dc3545 !important;
}
.border-warning {
  border: 2px solid #ffc107 !important;
}
.border-success {
  border: 2px solid #28a745 !important;
}
</style>
