import { $themeColors } from '@themeConfig'
export default {
    revenueComparisonLine: {
        chartOptions: {
          chart: {
            redrawOnWindowResize: true,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: [4,4,4],
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.success],
          dataLabels: {
              enabled: false,
            },
          xaxis: {
            tickAmount: 10,
            labels: {
              style: {
                colors: '#FFFFFF',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['2012', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            show: false,
            tickAmount: 0,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter: function(num) {
                var absNum = Math.abs(num); // Get the absolute value of num
                if (absNum >= 1000000000000) {
                    return (num < 0 ? "-" : "") + (absNum / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
                }
                if (absNum >= 1000000000) {
                    return (num < 0 ? "-" : "") + (absNum / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
                }
                if (absNum >= 1000000) {
                    return (num < 0 ? "-" : "") + (absNum / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                }
                if (absNum >= 1000) {
                    return (num < 0 ? "-" : "") + (absNum / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
                return num;
              }
            },
          },
          grid: {
            show: true,
            borderColor: '#000514',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      }
}