<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
         Projected future Growth
        </b-card-title>
        <b-card-sub-title> A maybe more simple and practical Valuation technique is the Average-Valuation-Technique. The factors that determine the value whit this method are historical average Price-to-Earnings, Price-to-Sales and Price-to-Free-Cashflow</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        id="realtimeChart"
        type="area"
        height="400"
        sm:height="200"
        :options="chartOptions" 
        :series="series"
      />
    </b-card-body>
  </b-card>
  
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'







export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,



  },
  data: function() {
      return {
        Rate:'',
        chartOptions: {
          chart: {
            id: 'vuechart-example',
            toolbar: {
              show: false
            },
          },
           legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
            toolbar: {
              show: false
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            colors: ['#28CD41'],
            marker: {
              size: 0,
            },
            yaxis:{
              show: false,
            },
            xaxis: {
              categories: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]
            }
        },
        series: [{
          data: [30, 40, 35, 50, 49, 60, 70, 91]
        }]
      }
  },
}
</script>
